import './App.css';
import Projet from './component/Projet';

function App() {
  return (
    <div className="App">
      <Projet />
    </div>
  );
}

export default App;
