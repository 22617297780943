import React, { useState, useEffect } from 'react';
import axios from 'axios';


function Projet() {


   const [projets, setProjets] = useState([]);
   const [loading, setLoading] = useState(true); // Loading state variable
   




   useEffect(() => {

      // Make HTTP requests to the API endpoints
      axios
         .all([
            axios.get('/api/projets'),
         ])
         .then((responseArr) => {
            const [projetsResponse] = responseArr;
            const projetsData = projetsResponse.data;
            setProjets(projetsData);
            setLoading(false); // Set loading to false after data is fetched



         })
         .catch((error) => {
            console.error('Error retrieving data:', error);
         });
   }, []);






   return (
      <div>

         {loading ? ( // Show the spinner while loading is true
            <div className="text-center">
               <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
               </div>
            </div>
         ) : (
            <div className="col-lg-12 grid-margin stretch-card">
               <div className="card">
                  <div className="card-body">
                     <h3 className="card-title">المشاريع</h3>
                     <table className="table table-striped">
                        <thead>
                           <tr>
                              <th> رقم </th>
                              <th> عنوان المشروع </th>
                              <th> المقاطعة</th>
                              <th> تاريخ البداية </th>
                              <th>تاريخ الانتهاء </th>
                              <th> الميزانية </th>
                              <th> تمويل ذاتي </th>
                              <th> نسبة الإنجاز </th>
                           </tr>
                        </thead>
                        <tbody>
                           {projets.map((projet) => (
                              <tr key={projet.id}>
                                 <td>{projet.n_projet}</td>
                                 <td>{projet.libelle}</td>
                                 <td>{projet.arrondissement_id}</td>
                                 <td>{new Date(projet.date_debut).toLocaleDateString()}</td>
                                 <td>{new Date(projet.date_fin).toLocaleDateString()}</td>
                                 <td>{projet.budget} DH</td>
                                 <td>{projet.auto_financement ? <label className="text-success">نعم</label> : <label className="text-danger">لا</label>}</td>
                                 <td> {projet.completion_rate ? <p>{projet.completion_rate} %</p> : <p>لا يوجد اي مؤشر</p>} </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>

                  </div>
               </div>
            </div>
         )}
      </div>
   );
}

export default Projet;